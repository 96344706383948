"use strict";
/**
 * Shared copy for outbound statement posting errors. Used by the frontend
 * to display error information to the user in app, and by the backend to
 * send over email. */
Object.defineProperty(exports, "__esModule", { value: true });
exports.vendorErrorCodeMapping = exports.vendorErrorCode = void 0;
/** Error codes uniquely identify an outbound statement posting error. Prefix
 * the error code with the AMS name. */
var vendorErrorCode;
(function (vendorErrorCode) {
    vendorErrorCode["AMS_360_POLICY_NOT_FOUND"] = "AMS_360_POLICY_NOT_FOUND";
    vendorErrorCode["AMS_360_MISMATCHED_CARRIER"] = "AMS_360_MISMATCHED_CARRIER";
    vendorErrorCode["EPIC_ACCOUNTING_MONTH_LOCKED"] = "EPIC_ACCOUNTING_MONTH_LOCKED";
    vendorErrorCode["EPIC_CLIENT_INVALID_NAME"] = "EPIC_CLIENT_INVALID_NAME";
    vendorErrorCode["EPIC_INVALID_PRODUCTION_CREDIT_PERCENT"] = "EPIC_INVALID_PRODUCTION_CREDIT_PERCENT";
    vendorErrorCode["EPIC_INVOICE_TO_MUST_BE_BROKER"] = "EPIC_INVOICE_TO_MUST_BE_BROKER";
    vendorErrorCode["EPIC_LINE_NOT_DIRECT_BILL"] = "EPIC_LINE_NOT_DIRECT_BILL";
    vendorErrorCode["EPIC_LINE_NOT_FOUND"] = "EPIC_LINE_NOT_FOUND";
    vendorErrorCode["EPIC_MISMATCHED_AGENCY_CODE"] = "EPIC_MISMATCHED_AGENCY_CODE";
    vendorErrorCode["EPIC_MISCONFIGURED_PPE"] = "EPIC_MISCONFIGURED_PPE";
    vendorErrorCode["EPIC_MISMATCHED_PPE"] = "EPIC_MISMATCHED_PPE";
    vendorErrorCode["EPIC_MISSING_BREC_PRBR"] = "EPIC_MISSING_BREC_PRBR";
    vendorErrorCode["EPIC_MISSING_ISSUING_COMPANY"] = "EPIC_MISSING_ISSUING_COMPANY";
    vendorErrorCode["EPIC_MULTIPLE_CARRIERS_IN_SAME_STATEMENT"] = "EPIC_MULTIPLE_CARRIERS_IN_SAME_STATEMENT";
    vendorErrorCode["EPIC_NON_COMMISSIONABLE_EMPLOYEE"] = "EPIC_NON_COMMISSIONABLE_EMPLOYEE";
    vendorErrorCode["EPIC_PRBR_MISSING_CONTRACT_ID"] = "EPIC_PRBR_MISSING_CONTRACT_ID";
    vendorErrorCode["EPIC_PRBR_BAD_STRUCTURE_ITEM"] = "EPIC_PRBR_BAD_STRUCTURE_ITEM";
    vendorErrorCode["EPIC_PRBR_INVALID_BPAY_COMMISSION_TYPE"] = "EPIC_PRBR_INVALID_BPAY_COMMISSION_TYPE";
    vendorErrorCode["EPIC_PRBR_MISMATCHED_SERVICING_TEAM"] = "EPIC_PRBR_MISMATCHED_SERVICING_TEAM";
    vendorErrorCode["EPIC_PRBR_COMMISSION_AGREEMENT_OUT_OF_DATE"] = "EPIC_PRBR_COMMISSION_AGREEMENT_OUT_OF_DATE";
    vendorErrorCode["EPIC_PRBR_COMMISSION_AGREEMENT_POLICY_STATUS_MISMATCH"] = "EPIC_PRBR_COMMISSION_AGREEMENT_POLICY_STATUS_MISMATCH";
    vendorErrorCode["EPIC_TEMPORARILY_UNAVAILABLE"] = "EPIC_TEMPORARILY_UNAVAILABLE";
    vendorErrorCode["EPIC_RECONCILE_STATEMENT_EXISTS"] = "EPIC_RECONCILE_STATEMENT_EXISTS";
    vendorErrorCode["HEADLESS_EPIC_STATEMENT_MIGHT_EXIST"] = "HEADLESS_EPIC_STATEMENT_MIGHT_EXIST";
    vendorErrorCode["AMS_360_MISSING_CARRIER_ORIGINATION_ADDRESS"] = "AMS_360_MISSING_CARRIER_ORIGINATION_ADDRESS";
})(vendorErrorCode || (exports.vendorErrorCode = vendorErrorCode = {}));
const portalBaseUrl = "https://q.comulate.com/comulate-help-center/admin/integrations";
const epicPortalBaseUrl = `${portalBaseUrl}/epic-integrations/epic-statement-posting-errors`;
const ams360PortalBaseUrl = `${portalBaseUrl}/ams360-integration/ams360-statement-posting-errors`;
/** Define the standard user-facing title for an error, as well as the URL for
 * troubleshooting information. */
exports.vendorErrorCodeMapping = {
    AMS_360_POLICY_NOT_FOUND: {
        title: "Policy not found",
        url: `${ams360PortalBaseUrl}#line-s-not-found`,
    },
    AMS_360_MISMATCHED_CARRIER: {
        title: "Mismatched carrier",
        url: `${ams360PortalBaseUrl}#mismatched-carrier`,
    },
    EPIC_ACCOUNTING_MONTH_LOCKED: {
        title: "Accounting month is locked, posted, or finalized",
        url: `${epicPortalBaseUrl}#accounting-month-is-locked-posted-or-finalized`,
    },
    EPIC_CLIENT_INVALID_NAME: {
        title: "Invalid client name",
        url: `${epicPortalBaseUrl}#invalid-client-name`,
    },
    EPIC_INVOICE_TO_MUST_BE_BROKER: {
        title: "Invoice must be sent to broker",
        url: `${epicPortalBaseUrl}#invoice-must-be-sent-to-broker`,
    },
    EPIC_INVALID_PRODUCTION_CREDIT_PERCENT: {
        title: "Production credit percentages must add up to 100%",
        url: `${epicPortalBaseUrl}#production-credit-percentages-must-add-up-to-100`,
    },
    EPIC_LINE_NOT_DIRECT_BILL: {
        title: "Lines not configured for direct bill",
        url: `${epicPortalBaseUrl}#line-must-be-direct-bill`,
    },
    EPIC_MISMATCHED_AGENCY_CODE: {
        title: "Mismatched agency code",
        url: `${epicPortalBaseUrl}#mismatched-agency-code`,
    },
    EPIC_MISCONFIGURED_PPE: {
        title: "Misconfigured premium payable entity (PPE)",
        url: `${epicPortalBaseUrl}#misconfigured-premium-payable-entity-ppe`,
    },
    EPIC_MISMATCHED_PPE: {
        title: "Mismatched premium payable entity (PPE)",
        url: `${epicPortalBaseUrl}#mismatched-premium-payable-entity-ppe`,
    },
    EPIC_MISSING_BREC_PRBR: {
        title: "Policy missing BREC Pr/Br(s)",
        url: `${epicPortalBaseUrl}#policy-missing-brec-pr-br-s`,
    },
    EPIC_MISSING_ISSUING_COMPANY: {
        title: "Missing issuing company (ICO)",
        url: `${epicPortalBaseUrl}#missing-issuing-company`,
    },
    EPIC_MULTIPLE_CARRIERS_IN_SAME_STATEMENT: {
        title: "Transactions reconciled to multiple carriers",
        url: `${epicPortalBaseUrl}#mismatched-premium-payable-entity-ppe`,
    },
    EPIC_NON_COMMISSIONABLE_EMPLOYEE: {
        title: "Employee cannot receive commission",
        url: `${epicPortalBaseUrl}#employee-cannot-receive-commission`,
    },
    EPIC_PRBR_MISSING_CONTRACT_ID: {
        title: "Pr/Br(s) missing contract",
        url: `${epicPortalBaseUrl}#missing-contract-for-broker-pr-br-s`,
    },
    EPIC_PRBR_BAD_STRUCTURE_ITEM: {
        title: "Pr/Br(s) have invalid structure",
        url: `${epicPortalBaseUrl}#invalid-structure-item-for-pr-br-s`,
    },
    EPIC_PRBR_INVALID_BPAY_COMMISSION_TYPE: {
        title: "Pr/Br(s) have invalid commission type",
        url: `${epicPortalBaseUrl}#invalid-commission-type-for-broker-pr-br-s`,
    },
    EPIC_PRBR_MISMATCHED_SERVICING_TEAM: {
        title: "Mismatched servicing contacts and Pr/Brs",
        url: `${epicPortalBaseUrl}#mismatched-servicing-contacts-and-pr-br-s`,
    },
    EPIC_PRBR_COMMISSION_AGREEMENT_OUT_OF_DATE: {
        title: "Commission agreement(s) out of date",
        url: `${epicPortalBaseUrl}#commission-agreement-out-of-date-for-pr-br-s`,
    },
    EPIC_PRBR_COMMISSION_AGREEMENT_POLICY_STATUS_MISMATCH: {
        title: "Mismatched line status and commission agreement",
        url: `${epicPortalBaseUrl}#commission-agreement-policy-status-mismatch-for-pr-br-s`,
    },
    EPIC_TEMPORARILY_UNAVAILABLE: {
        title: "Epic temporarily unavailable",
        url: `${epicPortalBaseUrl}#temporarily-unavailable`,
    },
    EPIC_LINE_NOT_FOUND: {
        title: "Line(s) not found",
        url: `${epicPortalBaseUrl}#line-s-not-found`,
    },
    EPIC_RECONCILE_STATEMENT_EXISTS: {
        title: "Reconcile statement already exists",
        url: `${epicPortalBaseUrl}#reconcile-statement-exists`,
    },
    HEADLESS_EPIC_STATEMENT_MIGHT_EXIST: {
        title: "Suspended statement already exists",
        url: "",
    },
    AMS_360_MISSING_CARRIER_ORIGINATION_ADDRESS: {
        title: "Carrier origination address is missing. Please reach out to your admin.",
        url: `${ams360PortalBaseUrl}#carrier-origination-address-is-missing`,
    },
};
