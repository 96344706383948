import { assertDefined } from "../../src/utils";
import { formatCarrierStatementName, pluralize } from "../../src/formatting";
import {
  BillingMode,
  StatementEntriesReconciledNotificationPayload,
} from "../../src/generated/graphql";
import { BaseNotification } from "./BaseNotification";

export const StatementEntriesReconciledNotification = ({
  payload: { statement, reconciledEventId, reconciledBy, numReconciledEntries },
  createdAt,
}: {
  payload: Omit<
    StatementEntriesReconciledNotificationPayload,
    "statementEntries"
  >;
  createdAt: string;
}) => {
  assertDefined(statement);

  const link =
    statement.billingMode === BillingMode.AGENCY_BILL
      ? `/accounts-payable?statementId=${statement.id}&reconciledEventId=${reconciledEventId}`
      : `/statements?statementId=${statement.id}&reconciledEventId=${reconciledEventId}`;

  return (
    <BaseNotification createdAt={createdAt} user={reconciledBy} href={link}>
      {({ userLabel }) => (
        <>
          <span className="font-medium text-zinc-900">{userLabel}</span>
          {" reconciled "} {pluralize(numReconciledEntries, "transaction")}
          {" from "}
          <span className="font-medium text-green-900">
            {formatCarrierStatementName(statement)}
          </span>
        </>
      )}
    </BaseNotification>
  );
};
