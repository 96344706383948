import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import {
  Auth0ContextInterface,
  Auth0Provider,
  useAuth0,
} from "@auth0/auth0-react";
import {
  ExclamationCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/20/solid";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { useRouter } from "next/router";
import * as React from "react";
import { useEffect, useMemo, useState } from "react";
import { Toaster, toast } from "sonner";
import "tippy.js/dist/tippy.css";
import { v4 as uuid } from "uuid";
import ErrorBoundary from "../components/ErrorBoundary";
import Loader from "../components/Loader";
import {
  LoggedInUserProvider,
  useLoggedInUser,
} from "../components/LoggedInUserProvider";
import { isNullOrUndefined } from "../src/formatting";
import { UserRole } from "../src/generated/graphql";
import { assertUnreachableValue } from "../src/utils";
import "../styles/globals.css";
import "setimmediate";
import Toast from "../components/Toast";
import { Button } from "../components/Button";
import { useInterval } from "../hooks/useInterval";
import { UserAvatarUrlsProvider } from "../hooks/useUserAvatarUrls";
import logger from "../src/logger";
import { logEvent } from "../src/analytics/common";
import { setUseWhatChange } from "@simbathesailor/use-what-changed";
import { LATEST_APP_VERSION } from "./api/requires-refresh/[version]";
import { relayStylePagination } from "@apollo/client/utilities";
import fetch from "cross-fetch";
import { FeatureFlagProvider } from "../feature-flags/FeatureFlagProvider";
import { cacheSizes } from "@apollo/client/utilities";
import { isExtensionEnabled, sendWebextMessage } from "../src/webextUtils";
import { tippy } from "@tippyjs/react";
import Appbar from "../components/Appbar";
import { GLOBAL_NAV } from "../components/Appbar/navigationConstants";
import { RetryLink } from "@apollo/client/link/retry";
import { OperationDefinitionNode } from "graphql";

// Initialize LogRocket
import "../src/logRocket";

// Cache sizes + limits available in __APOLLO_CLIENT__.getMemoryInternals()
cacheSizes["inMemoryCache.executeSelectionSet"] = 50_000;
cacheSizes["inMemoryCache.executeSubSelectedArray"] = 50_000;

// By default, tippy.js sets a max-width of 350px on tooltips. This disables that default so that
// we can set it ourselves.
tippy.setDefaultProps({ maxWidth: "" });

export type PageWithLayout<P = unknown, IP = P> = NextPage<P, IP> & {
  getLayout?: ({ children }: { children: React.ReactNode }) => JSX.Element;
};

type AppPropsWithLayout = AppProps & {
  Component: PageWithLayout;
};

setUseWhatChange(true);

const ENABLE_MSW =
  process.env.NODE_ENV === "development" &&
  process.env.NEXT_PUBLIC_API_MOCKING === "enabled";

export default function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const router = useRouter();

  const [shouldRender, setShouldRender] = useState(!ENABLE_MSW);

  useEffect(() => {
    async function startMockServiceWoker() {
      // See https://github.com/mswjs/msw/discussions/1049 for context
      // - handles race condition between worker readiness and requests
      const { initMocks } = await import("../msw");
      await initMocks();
      setShouldRender(true);
    }

    if (ENABLE_MSW) {
      startMockServiceWoker();
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const analytics = (window as any).analytics;

      // Guard against adblockers.
      if (analytics && typeof analytics.load === "function") {
        analytics._writeKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;
        analytics.load(process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY);
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const analytics = (window as any).analytics;

      // Guard against adblockers.
      if (analytics && typeof analytics.page === "function") {
        analytics.page();
      }
    }
  }, [router.route]);

  const Layout = Component.getLayout ? Component.getLayout : DefaultLayout;

  if (
    process.env.NEXT_PUBLIC_AUTH0_DOMAIN === undefined ||
    process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID === undefined
  )
    throw new Error("AUTH0_DOMAIN or AUTH0_CLIENT_ID is undefined");

  const shouldPollForRefresh = process.env.NODE_ENV !== "development";

  if (!shouldRender) {
    return null;
  }

  const loginWebext = async () => {
    const extensionEnabled = await isExtensionEnabled();
    if (extensionEnabled) {
      sendWebextMessage({ type: "AuthenticateUser", request: {} });
    }
  };

  /**
   * OIDC query params in the URL can cause browser refreshes to render the MFA
   * page, even if a user is already logged in.
   */
  const stripOidcParamsFromUrl = () => {
    const { asPath } = router;
    const url = new URL(asPath, window.location.origin);
    const oidcParams = ["code", "state"];

    oidcParams.forEach((p) => url.searchParams.delete(p));
    router.push(url.pathname + url.search, undefined, { shallow: true });
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      resetKeys={[router.pathname]}
    >
      <Auth0Provider
        domain={process.env.NEXT_PUBLIC_AUTH0_DOMAIN}
        clientId={process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID}
        redirectUri={process.env.NEXT_PUBLIC_AUTH0_REDIRECT_URL}
        audience={process.env.NEXT_PUBLIC_AUTH0_AUDIENCE}
        onRedirectCallback={async () => {
          await loginWebext();
          stripOidcParamsFromUrl();
        }}
        cacheLocation="localstorage"
      >
        <ApiProvider>
          <FeatureFlagProvider>
            <LoggedInUserProvider>
              <LoginHandler>
                <Permissions>
                  <UserAvatarUrlsProvider>
                    <div className="flex flex-col w-full h-screen">
                      <Appbar />
                      <div className="h-full min-h-0">
                        <Layout>
                          <Toaster position="bottom-left" />
                          {shouldPollForRefresh && <RefreshChecker />}
                          <ErrorBoundary
                            FallbackComponent={ErrorFallback}
                            resetKeys={[router.pathname]}
                          >
                            <Component {...pageProps} />
                          </ErrorBoundary>
                        </Layout>
                      </div>
                    </div>
                  </UserAvatarUrlsProvider>
                </Permissions>
              </LoginHandler>
            </LoggedInUserProvider>
          </FeatureFlagProvider>
        </ApiProvider>
      </Auth0Provider>
    </ErrorBoundary>
  );
}

const versionOnLoad = LATEST_APP_VERSION;
let refreshToastTriggered = false;
const RefreshChecker = () => {
  const triggerRefreshToast = React.useCallback(() => {
    if (!refreshToastTriggered) {
      let toastId: string | number | undefined = undefined;
      toastId = toast(
        <Toast
          displayCloseButton={false}
          header={{
            content: (
              <span className={"text-green-700 mt-1 text-base leading-5"}>
                New app version available
              </span>
            ),
          }}
          headerIcon={
            <ArrowPathIcon className="w-4 mr-3 mt-0.5 text-green-700 flex-shrink-0" />
          }
          body={{
            content: (
              <React.Fragment>
                <span className="mt-1 text-green-700 text-sm leading-5">
                  To ensure a reliable and fast Comulate experience, please
                  refresh this browser tab to use the latest app version
                </span>
                <Button
                  size="xs"
                  colorScheme="primary"
                  className="w-fit mt-2"
                  onClick={() => {
                    logEvent("Requires Refresh Prompt Accepted", {
                      versionOnLoad: versionOnLoad,
                    });
                    window.location.reload();
                  }}
                >
                  Refresh
                </Button>
              </React.Fragment>
            ),
          }}
          onClose={() => toast.dismiss(toastId)}
        />,
        { duration: Infinity }
      );
      refreshToastTriggered = true;
    }
  }, []);

  async function fetchAPIData() {
    const url = "/api/requires-refresh/" + versionOnLoad;
    await fetch(url)
      .then((res) => {
        if (res.ok) {
          const result = res.json();
          result.then((needsRefreshResult) => {
            if (needsRefreshResult && !refreshToastTriggered) {
              triggerRefreshToast();
            }
          });
        }
      })
      .catch(() => {
        logger.warn("Error fetching requires-refresh status");
      });
  }

  useInterval(fetchAPIData, 60_000);
  return <></>;
};

const adminOnlyPathRegexes = [/^\/settings\/organization.*$/];
const payeeOnlyPathRegexes = [GLOBAL_NAV.EARNINGS.regex];
const revenueAnalystPathRegexes = [
  GLOBAL_NAV.DASHBOARD.regex,
  GLOBAL_NAV.INSIGHTS.regex,
  GLOBAL_NAV.MORE.regex,
  /^\/download-file$/,
  /^\/settings\/.*$/,
];
const triagerPathRegexes = [GLOBAL_NAV.TRIAGE.regex, /^\/settings\/.*$/];

const REDIRECT_PATH_FOR_ROLE: Record<UserRole, string> = {
  [UserRole.ADMIN]: "/",
  [UserRole.STANDARD]: "/",
  [UserRole.PAYEE]: "/earnings",
  [UserRole.REVENUE_ANALYST]: "/",
  [UserRole.TRIAGER]: "/triage",
  [UserRole.TRIAGE_VIEWER]: "/triage",
};
const Permissions = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { user } = useLoggedInUser();

  const pathIsAllowed = useMemo(() => {
    const role = user?.role;
    if (isNullOrUndefined(role)) return false;
    const path = router.pathname;

    if (adminOnlyPathRegexes.some((regex) => regex.test(path))) {
      return user?.role === UserRole.ADMIN;
    }

    switch (role) {
      case UserRole.ADMIN: {
        return true;
      }
      case UserRole.STANDARD: {
        return !payeeOnlyPathRegexes.every((regex) => regex.test(path));
      }
      case UserRole.PAYEE: {
        return payeeOnlyPathRegexes.some((regex) => regex.test(path));
      }
      case UserRole.REVENUE_ANALYST: {
        /** Analysts are not allowed to view the statements page, but they do
         * need the ability to analyze the entries within a specific statement.
         * The statements UI uses a `statementId` query param to display the
         * details modal.
         * */
        if (
          GLOBAL_NAV.STATEMENTS.regex.test(path) &&
          router.query.statementId
        ) {
          return true;
        }

        return revenueAnalystPathRegexes.some((regex) => regex.test(path));
      }
      case UserRole.TRIAGER:
      case UserRole.TRIAGE_VIEWER: {
        return triagerPathRegexes.some((regex) => regex.test(path));
      }
      default:
        return assertUnreachableValue(role);
    }
  }, [user?.role, router.pathname, router.query.statementId]);

  if (pathIsAllowed) {
    // Path allowed, render children
    return <React.Fragment>{children}</React.Fragment>;
  } else {
    // Path now allowed, must redirect
    const redirectPath = user?.role ? REDIRECT_PATH_FOR_ROLE[user.role] : "/";
    router.replace(redirectPath);
    return (
      <div className="flex justify-center items-center h-screen w-screen">
        <Loader />
      </div>
    );
  }
};

const DefaultLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <div className="max-w-8xl w-full mx-auto px-4 h-full min-h-0">
      {children}
    </div>
  );
};

const ErrorFallback = () => {
  return (
    <div className="flex flex-col space-y-4 justify-center items-center h-[70vh] w-full">
      <div>
        <ExclamationCircleIcon className="h-9 w-9 text-zinc-400"></ExclamationCircleIcon>
      </div>

      <h1 className="text-lg text-zinc-800">That didn't go how we expected</h1>
      <p className="text-sm text-zinc-800 max-w-md text-center leading-6">
        An error has occurred and we've been notified. You may try again in a
        few minutes or{" "}
        <a href="mailto:help@comulate.com" className="text-green-700">
          contact support.
        </a>
      </p>
    </div>
  );
};

function LoginHandler({ children }) {
  const authContext = useAuth0();
  const loggedInUserContext = useLoggedInUser();
  const router = useRouter();
  const shouldLogout = router.query.logout === "true";

  useEffect(() => {
    if (shouldLogout) {
      authContext.logout({
        returnTo: window.location.origin,
      });
    }
  }, [shouldLogout, authContext]);

  useEffect(() => {
    if (
      window.LogRocket &&
      authContext.isLoading === false &&
      loggedInUserContext.isLoading === false &&
      typeof loggedInUserContext.user?.id === "string" &&
      typeof loggedInUserContext.organization?.id === "string"
    ) {
      window.LogRocket.identify(loggedInUserContext.user.id, {
        name: loggedInUserContext.user.fullName,
        email: loggedInUserContext.user.email,
        organizationId: loggedInUserContext.organization?.id,
      });
    }
  }, [
    loggedInUserContext.user,
    loggedInUserContext.organization?.id,
    loggedInUserContext.isLoading,
    authContext.isLoading,
  ]);

  if (
    authContext.isLoading === false &&
    authContext.isAuthenticated === false
  ) {
    authContext.loginWithRedirect();
    return null;
  }

  if (loggedInUserContext.isError) {
    return <ErrorFallback />;
  }

  if (
    authContext.isLoading === false &&
    loggedInUserContext.isLoading === false
  ) {
    return <>{children}</>;
  }

  return (
    <div className="flex justify-center items-center h-screen w-screen">
      <Loader />
    </div>
  );
}

const apiUrl = new URL("/graphql", process.env.NEXT_PUBLIC_API_URL).toString();
const httpLink = createHttpLink({
  uri: apiUrl,
  fetch,
});
let tokenFetcher: Auth0ContextInterface["getAccessTokenSilently"] | null = null;
const authLink = setContext(async (_, { headers }) => {
  if (tokenFetcher === null) {
    throw new Error(
      "Auth0 token fetcher has not yet been set, cannot make request"
    );
  }

  const token = await tokenFetcher();

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
      ...(window.LogRocket && {
        "X-LogRocket-URL": window.LogRocket.sessionURL,
      }),
      "X-Request-ID": uuid(),
    },
  };
});

/** Provide consistent keys for caching relay-style paginated query results.
 * https://www.apollographql.com/docs/react/pagination/key-args/#keyargs-function-advanced */
export const relayStylePaginationKeyArgs = (
  args: Record<string, any> | null
): string[] => {
  if (args === null) return [];

  const paginationArgs = ["first", "after"];
  return Object.keys(args)
    .filter((name) => !paginationArgs.includes(name))
    .sort((name1, name2) => (name1 > name2 ? 1 : -1));
};

export const cache = new InMemoryCache({
  typePolicies: {
    CarrierStatementUpload: {
      fields: {
        inferredStatementData: {
          merge: true,
        },
        externalMetadata: {
          merge: true,
        },
      },
    },
    Carrier: {
      fields: {
        vendorConfig: {
          keyArgs: false,
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
    IntegrationGLItem: {
      fields: {
        additionalFields: {
          merge: true,
        },
      },
    },
    Integration: {
      fields: {
        vendorConfig: {
          merge: true,
        },
      },
    },
    CarrierStatement: {
      fields: {
        inferredStatementData: {
          merge: true,
        },
        externalMetadata: {
          merge: true,
        },
      },
    },
    Query: {
      fields: {
        inboundEmails: relayStylePagination(relayStylePaginationKeyArgs),
        cashReceivedBatches: relayStylePagination(relayStylePaginationKeyArgs),
        cashReceivedPayments: relayStylePagination(relayStylePaginationKeyArgs),
        statements: relayStylePagination(relayStylePaginationKeyArgs),
        policies: relayStylePagination(relayStylePaginationKeyArgs),
        clientsPaginated: relayStylePagination(relayStylePaginationKeyArgs),
        revenueForecastGroups: relayStylePagination(
          relayStylePaginationKeyArgs
        ),
        virtualOutboundStatements: relayStylePagination(
          relayStylePaginationKeyArgs
        ),
        carrierCompGroups: relayStylePagination(relayStylePaginationKeyArgs),
        statementEntries: relayStylePagination(relayStylePaginationKeyArgs),
        notifications: relayStylePagination(relayStylePaginationKeyArgs),
        invoiceTransactions: relayStylePagination(relayStylePaginationKeyArgs),
        invoiceStatementPayableEntries: relayStylePagination(
          relayStylePaginationKeyArgs
        ),
        premiumBillings: relayStylePagination(relayStylePaginationKeyArgs),
        integrationEmployees: relayStylePagination(relayStylePaginationKeyArgs),
        triagedStatementEntryGroups: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        statementUploads: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        invoiceTxnAssociations: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        appliedCarrierStatements: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (_, operation) => {
      const operationDefinition = operation.query.definitions.find(
        (def): def is OperationDefinitionNode =>
          def.kind === "OperationDefinition"
      );

      // Only retry queries
      return operationDefinition?.operation === "query";
    },
  },
});

export const client = new ApolloClient({
  cache,
  connectToDevTools: true,
  // HTTP link must follow the retry link
  link: authLink.concat(retryLink).concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

function ApiProvider({ children }) {
  const { getAccessTokenSilently } = useAuth0();
  tokenFetcher = getAccessTokenSilently;

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
