// organize-imports-ignore
// Ensure that polyfill of Intl.NumberFormat loaded
import "@formatjs/intl-getcanonicallocales/polyfill";
import "@formatjs/intl-locale/polyfill";
import "@formatjs/intl-pluralrules/polyfill";
import "@formatjs/intl-numberformat/polyfill";
import "@formatjs/intl-numberformat/locale-data/en";

import { DateTime } from "luxon";
import {
  BillingMode,
  CashReceivedBatchProcessedNotificationPayload,
  IntegrationsQuery,
  StatementEntriesReconciledNotificationPayload,
  StatementImportedNotificationPayload,
  StatementPostedNotificationPayload,
  SuccessorPayableStatementCreatedNotificationPayloadFragmentFragment,
} from "./generated/graphql";
import { assertUnreachableValue } from "./utils";
import * as pluralizeLib from "pluralize";
import { twMerge } from "tailwind-merge";

export const formatPhoneNumber = (numberWithAreaCode: string) => {
  const cleaned = numberWithAreaCode.replace(/\D/g, "");

  if (cleaned.length >= 10) {
    const digits = cleaned.slice(-10);

    const phoneNumber = digits.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    return phoneNumber;
  }
  return numberWithAreaCode; // Failed to format, just return number we have
};

const DOLLAR_FORMATTER_2_DECIMALS = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const DOLLAR_FORMATTER_0_DECIMALS = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
  style: "currency",
  currency: "USD",
});

const COMPACT_FORMATTER_2_SIG_FIGS = Intl.NumberFormat("en-US", {
  notation: "compact",
  minimumSignificantDigits: 2,
  maximumSignificantDigits: 2,
});

const COMPACT_FORMATTER_0_DECIMALS = Intl.NumberFormat("en-US", {
  notation: "compact",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

/**
 * Returns string representing dollars (e.g. 1,234.56) without a currency symbol.
 */
export const formatDollarsNoSymbol = (value: number): string =>
  `${value < 0 ? "-" : ""}${DOLLAR_FORMATTER_2_DECIMALS.format(
    Math.abs(value)
  )}`;

/**
 * Returns string representing dollars (e.g. $1,234.56).
 */
export const formatDollars = (value: number): string =>
  `${value < 0 ? "-" : ""}$${DOLLAR_FORMATTER_2_DECIMALS.format(
    Math.abs(value)
  )}`;

/**
 * Returns formatted string with no dollar symbol, but showing + or -.
 * Examples: +1,234.56, -1,234.56
 */
export const formatDollarsWithSignNoSymbol = (value: number): string =>
  `${value < 0 ? "-" : "+"}${DOLLAR_FORMATTER_2_DECIMALS.format(
    Math.abs(value)
  )}`;

/**
 * Returns formatted string rounded to nearest whole dollar.
 * Examples: $1,235, -$1,235
 */
export const formatDollarsWithoutCents = (value: number): string =>
  DOLLAR_FORMATTER_0_DECIMALS.format(value);

/**
 * Returns formatted string, rounding values <= -1,000 and >= 1,000 to 2 significant figures.
 * Examples: $1.2K, -$1.2M
 * Returns rounded formatted whole numbers for values > -1,000 and < 1,000.
 * Examples: -$999, $123
 */
export const formatDollarsCompact = (value: number): string => {
  const absValue = Math.abs(value);
  const sign = value < 0 ? "-" : "";
  if (absValue >= 1_000) {
    return `${sign}$${COMPACT_FORMATTER_2_SIG_FIGS.format(absValue)}`;
  }
  return `${sign}$${COMPACT_FORMATTER_0_DECIMALS.format(absValue)}`;
};

/** Helper to format a value that is expected to be outside of given range.
 * If value is within rounded range, displays the rounded (i.e. compact) range. Else, displays
 * the non-rounded range.
 */
export const formatOutOfRangeDollars = (
  minRange: number,
  maxRange: number,
  outOfRangeValue: number | null | undefined
) => {
  const minRangeRounded = Number(minRange.toPrecision(2));
  const maxRangeRounded = Number(maxRange.toPrecision(2));
  if (
    outOfRangeValue &&
    outOfRangeValue >= minRangeRounded &&
    outOfRangeValue <= maxRangeRounded
  ) {
    // Value that is out of original range is within rounded range, so don't display the rounded range.
    return `${formatDollars(minRange)} – ${formatDollars(maxRange)}`;
  } else {
    return `${formatDollarsCompact(minRange)} – ${formatDollarsCompact(
      maxRange
    )}`;
  }
};

/**
 * Formats percentage as string. Assumes percentage in range of 0-100.
 * @param value
 */
export const formatPercent = (value: number, numDecimals = 2): string => {
  return `${value.toLocaleString(undefined, {
    minimumFractionDigits: numDecimals,
    maximumFractionDigits: numDecimals,
  })}%`;
};

/**
 * Formats percentage as string, with truncation. Assumes percentage in range of 0-100.
 * 56 -> "56%"
 * 56.0 -> "56%"
 * 56.1 -> "56.1%"
 * 56.12 -> "56.12%"
 * 56.127 -> "56.12%"
 */
export const formatPercentWithTruncation = (percent: number, numDecimals = 2) =>
  formatPercent(percent, Number.isInteger(percent) ? 0 : numDecimals);

export const formatDeltaPercent = (value: number): string => {
  const rootPercentFormat = Math.abs(value).toLocaleString(undefined, {
    style: "percent",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

  return `${value < 0 ? "-" : "+"}${rootPercentFormat}`;
};

const getNumberSuffix = (num: number) => {
  const th = "th";
  const rd = "rd";
  const nd = "nd";
  const st = "st";

  if (num === 11 || num === 12 || num === 13) return th;

  const lastDigit = num.toString().slice(-1);

  switch (lastDigit) {
    case "1":
      return st;
    case "2":
      return nd;
    case "3":
      return rd;
    default:
      return th;
  }
};

/**
 * Formats a date as an ordinal. For example: August 13th.
 * @param date
 */
export const formatOrdinalDate = (date: DateTime): string => {
  const month = date.monthLong;
  const daySuffix = getNumberSuffix(date.day);

  return `${month} ${date.day}${daySuffix}`;
};

export const getInitials = (name: string) => {
  const components = name
    .split(" ")
    .slice(0, 2)
    .filter((s) => s.length > 0);
  if (components.length === 1) return components[0][0].toUpperCase();

  return components.map((n) => n[0].toUpperCase()).join("");
};

export const getFirstNameLastInitial = (name: string): string => {
  const [firstName, lastName] = name.split(" ");
  const lastInital = lastName.charAt(0).toUpperCase();
  return `${firstName} ${lastInital}`;
};

export const classNames = (...classes) => twMerge(...classes.filter(Boolean));

export const isNotNullAndNotUndefined = <T>(
  arg: T | undefined | null
): arg is T => {
  return arg !== null && arg !== undefined;
};

export const isNullOrUndefined = <T>(
  arg: T | null | undefined
): arg is null | undefined => {
  return arg === null || arg === undefined;
};

// Takes a period, returns the formatted name
export const getPeriodName = (period) => {
  if (typeof period === "object") {
    return toMonthString(period.rangeStart);
  }
  return toMonthString(period);
};

export enum DateFormat {
  /** Formats dates like 1/1/24 */
  DATE_STANDARD = "DATE_STANDARD",
  /** Formats dates like 1/1 */
  DATE_STANDARD_SHORT = "DATE_STANDARD_SHORT",
  /** Formats dates like 1/1/24 2:05pm */
  DATE_WITH_TIME = "DATE_WITH_TIME",
  /** Formats dates like 1/1 2:05pm */
  DATE_WITH_TIME_SHORT = "DATE_WITH_TIME_SHORT",
  /** Formats dates like Jan 1 2024 2:05pm */
  DATE_NAME_WITH_TIME = "DATE_NAME_WITH_TIME",
  /** Formats dates like Jan 1 */
  DATE_NAME_SHORT = "DATE_NAME_SHORT",
  /** Formats dates like 1/12 */
  DATE_SHORT = "DATE_SHORT",
  /** Formats dates like March 2020 */
  DATE_MONTH_WITH_YEAR = "DATE_MONTH_WITH_YEAR",
}

/**
 * Formats provided date into standardized date format. This method
 * should be used for *all* date formatting throughout app. We should
 * aim to keep the pool of formats as small as possible and only expand
 * if very necessary.
 *
 * @param dt
 * @param format
 * @returns
 */
export const formatDate = (
  dt: DateTime,
  format: DateFormat = DateFormat.DATE_STANDARD
) => {
  switch (format) {
    case DateFormat.DATE_STANDARD:
      return dt.toFormat("M/d/yy");
    case DateFormat.DATE_STANDARD_SHORT:
      return dt.toFormat("M/d");
    case DateFormat.DATE_WITH_TIME:
      return dt.toFormat("M/d/yy h:mma").toLowerCase();
    case DateFormat.DATE_WITH_TIME_SHORT:
      return dt.toFormat("M/d h:mma").toLowerCase();
    case DateFormat.DATE_NAME_WITH_TIME:
      return capitalize(dt.toFormat("LLL d yyyy h:mma").toLowerCase());
    case DateFormat.DATE_NAME_SHORT:
      return capitalize(dt.toFormat("LLL d").toLowerCase());
    case DateFormat.DATE_SHORT:
      return dt.toFormat("M/d");
    case DateFormat.DATE_MONTH_WITH_YEAR:
      return dt.toFormat("MMMM yyyy");
    default:
      return assertUnreachableValue(format);
  }
};

// Takes in ISO formatted string, returns string formatted as MMMM yyyy (e.g. March 2020)
export const toMonthString = (dateString: string) =>
  DateTime.fromISO(dateString).toUTC().toFormat("MMMM yyyy");

export const formatExpectedAmount = (expectedAmount: any) => {
  if (expectedAmount.type === "range") {
    return `${formatDollarsCompact(
      expectedAmount.minRange
    )} – ${formatDollarsCompact(expectedAmount.maxRange)}`;
  }
  return formatDollars(expectedAmount.value);
};

export const formatPremiumRange = (
  expectedPremiumConfig: any,
  premium: number
) => {
  const max = premium * (1 + expectedPremiumConfig.maxPercentage);
  const min = premium * (1 - expectedPremiumConfig.minPercentage);
  return `${formatDollarsCompact(min)}  - ${formatDollarsCompact(max)}`;
};

type IntegrationDisplay = IntegrationsQuery["integrations"][number];
export const integrationDisplayName = (
  integration: Pick<IntegrationDisplay, "name" | "vendorConfig">
) => {
  const vendorConfigType = integration.vendorConfig.__typename;

  switch (vendorConfigType) {
    case "EpicVendorConfig":
      return "Epic";

    case "SalesforceVendorConfig":
      return "Salesforce";

    case "BenefitPointVendorConfig":
      return "BenefitPoint";

    case "AMS360VendorConfig":
      return "AMS360";

    case "DemoVendorConfig":
      return integration.vendorConfig.vendorDisplayName;

    case "FixedVendorConfig":
      return integration.name;

    case undefined:
      throw new Error("Unknown vendor config type");

    case "SagittaVendorConfig":
      return "Sagitta";

    case "AgencyBlocVendorConfig":
      return "AgencyBloc";

    default:
      return assertUnreachableValue(vendorConfigType);
  }
};

export const roundToCents = (num: number) => Math.round(num * 100) / 100;

export const splitCamelCase = (str: string) =>
  str.replace(/([a-z0-9_-])([A-Z])/g, "$1 $2");

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// Out of the box, custom pluralization configurations should not be very
// necessary. However, if you want some custom behavior for how to pluralize or
// singularize, you can do so here.
export type PluralizeConfiguration = {
  pluralRule: { regex: string; replacement: string }; // ex: regex: gex$/i replacement: gexii (regex -> regexii)
  singularRule: { regex: string; replacement: string }; // ex: regex: /singles$/i replacement: singular (singles -> singular)
};

/**
 * Converts a word to the correct pluralization based on the count.
 * @param count The number of items of the noun
 * @param noun The word to pluralize or singularize
 * @param includeCount Whether to include count in the output string, e.g. "1 item" vs "item"
 * @param pluralizeBehaviorConfigurations Custom advanced pluralization behavior
 * @returns
 */
export const pluralize = (
  count: number,
  noun: string,
  includeCount = true,
  pluralizeBehaviorConfigurations?: PluralizeConfiguration
) => {
  const isSingular = Math.abs(count) === 1;

  // Adds a rule to pluralize library to handle custom pluralization behavior
  const addRuleToLibrary = () => {
    if (!pluralizeBehaviorConfigurations) return;

    if (isSingular && pluralizeBehaviorConfigurations.singularRule) {
      pluralizeLib.addSingularRule(
        pluralizeBehaviorConfigurations.singularRule.regex,
        pluralizeBehaviorConfigurations.singularRule.replacement
      );
    } else if (!isSingular && pluralizeBehaviorConfigurations.pluralRule) {
      pluralizeLib.addPluralRule(
        pluralizeBehaviorConfigurations.pluralRule.regex,
        pluralizeBehaviorConfigurations.pluralRule.replacement
      );
    }
  };

  // gets noun with correct pluralization
  const getFormattedNoun = () => {
    if (isSingular) {
      return pluralizeLib.singular(noun);
    } else {
      return pluralizeLib.plural(noun);
    }
  };

  addRuleToLibrary(); // adds rules as necessary
  const formattedNoun = getFormattedNoun(); // formats noun
  const countPrefix = includeCount ? `${count} ` : ""; // adds count prefix if applicable

  return countPrefix + formattedNoun;
};

/**
 * Given a statement with carrier name, billing mode, totalRevenue, totalNetPremium, and createdAt,
 * returns a standardized pretty name for summary views (e.g., notifications) in the frontend.
 *
 * @param statement { carrier: { name: string }, totalRevenue: number, createdAt: string }
 * @returns pretty carrier statement name, formatted as ${carrier.name} · ${totalRevenue} · ${date}
 */
export const formatCarrierStatementName = (
  statement:
    | NonNullable<StatementEntriesReconciledNotificationPayload["statement"]>
    | NonNullable<StatementImportedNotificationPayload["statement"]>
    | NonNullable<StatementPostedNotificationPayload["statement"]>
    | NonNullable<
        SuccessorPayableStatementCreatedNotificationPayloadFragmentFragment["successorStatement"]
      >
) => {
  const carrier = statement.carrier.name;
  const amount =
    statement.billingMode === BillingMode.AGENCY_BILL
      ? formatDollars(statement.totalNetPremium)
      : formatDollars(statement.totalRevenue);
  const date = DateTime.fromISO(statement.createdAt)
    .toFormat("M/d h:mma")
    .toLocaleLowerCase();

  return `${carrier} · ${amount} · ${date}`;
};

/**
 * Given a cashReceivedBatch returns a standardized
 * pretty name for summary views (e.g., notifications) in the frontend.
 *
 * @param cashReceivedBatch
 * @returns pretty carrier statement name, formatted as ${file.name}
 */
export const formatCashReceivedBatchName = (
  cashReceivedBatch: NonNullable<
    CashReceivedBatchProcessedNotificationPayload["cashReceivedBatch"]
  >
) => {
  const fileName = cashReceivedBatch.file.name;
  return `${fileName}`;
};
/**
 * Given a ISO-compatible datetime string, returns a standardized relative time.
 *
 * @param isoDateTime ISO-compatible datetime string
 * @returns pretty relative time, human readable as in "just now" or a dateTime.toRelative() string
 */
export const formatRelativeTimestamp = (isoDateTime: string) => {
  const dateTime = DateTime.fromISO(isoDateTime);
  const diff = dateTime.diffNow().negate();

  if (diff.as("minutes") < 1) return "just now";

  if (diff.as("hours") < 1) {
    return dateTime.toRelative({ unit: "minutes" });
  }

  if (dateTime.hasSame(DateTime.now(), "day")) {
    return dateTime.toRelative({ unit: "hours" });
  }

  if (dateTime.hasSame(DateTime.now().minus({ days: 1 }), "day")) {
    return `yesterday ${dateTime.toFormat("h:mma").toLocaleLowerCase()}`;
  }

  return dateTime.toFormat("M/d h:mma").toLocaleLowerCase();
};
