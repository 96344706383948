"use strict";
/* eslint-disable @typescript-eslint/naming-convention */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TagColor = exports.StatementIdentifierField = exports.ImportFieldOperation = exports.StatementRowFilterRuleType = exports.RelativePosition = exports.TextBasis = exports.DepositField = exports.ImportFieldType = exports.ImportFieldAccessorType = exports.ImportField = exports.DurationUnit = exports.RecordExtractorConfigType = exports.AdemFieldType = exports.AdemFieldAlignment = exports.AdemBlockMatchPolicyType = exports.AdemBoundaryConfigType = exports.BillingMode = exports.AdemField = void 0;
var AdemField;
(function (AdemField) {
    AdemField["CLIENT"] = "CLIENT";
    AdemField["COMMISSION"] = "COMMISSION";
    AdemField["CONTINGENT"] = "CONTINGENT";
    AdemField["CUSTOM"] = "CUSTOM";
    AdemField["DATE"] = "DATE";
    AdemField["LINE"] = "LINE";
    AdemField["MEMBERS"] = "MEMBERS";
    AdemField["POLICY_NUMBER"] = "POLICY_NUMBER";
    AdemField["PREMIUM"] = "PREMIUM";
    AdemField["NET_PREMIUM"] = "NET_PREMIUM";
    AdemField["RATE"] = "RATE";
    AdemField["TRANSACTION_CODE"] = "TRANSACTION_CODE";
})(AdemField || (exports.AdemField = AdemField = {}));
var BillingMode;
(function (BillingMode) {
    BillingMode["AGENCY_BILL"] = "AGENCY_BILL";
    BillingMode["DIRECT_BILL"] = "DIRECT_BILL";
})(BillingMode || (exports.BillingMode = BillingMode = {}));
var AdemBoundaryConfigType;
(function (AdemBoundaryConfigType) {
    AdemBoundaryConfigType["CELL_TYPE"] = "CELL_TYPE";
    AdemBoundaryConfigType["FIXED_BLOCKS"] = "FIXED_BLOCKS";
    AdemBoundaryConfigType["POSITION"] = "POSITION";
    AdemBoundaryConfigType["RELATIVE_FIELD"] = "RELATIVE_FIELD";
})(AdemBoundaryConfigType || (exports.AdemBoundaryConfigType = AdemBoundaryConfigType = {}));
var AdemBlockMatchPolicyType;
(function (AdemBlockMatchPolicyType) {
    AdemBlockMatchPolicyType["FIXED_ELIMINATION"] = "FIXED_ELIMINATION";
})(AdemBlockMatchPolicyType || (exports.AdemBlockMatchPolicyType = AdemBlockMatchPolicyType = {}));
var AdemFieldAlignment;
(function (AdemFieldAlignment) {
    AdemFieldAlignment["CENTER"] = "CENTER";
    AdemFieldAlignment["LEFT"] = "LEFT";
    AdemFieldAlignment["RIGHT"] = "RIGHT";
})(AdemFieldAlignment || (exports.AdemFieldAlignment = AdemFieldAlignment = {}));
var AdemFieldType;
(function (AdemFieldType) {
    AdemFieldType["DATE"] = "DATE";
    AdemFieldType["NUMBER"] = "NUMBER";
    AdemFieldType["STRING"] = "STRING";
})(AdemFieldType || (exports.AdemFieldType = AdemFieldType = {}));
var RecordExtractorConfigType;
(function (RecordExtractorConfigType) {
    RecordExtractorConfigType["ROW_SIGNATURE"] = "ROW_SIGNATURE";
})(RecordExtractorConfigType || (exports.RecordExtractorConfigType = RecordExtractorConfigType = {}));
var DurationUnit;
(function (DurationUnit) {
    DurationUnit["DAY"] = "DAY";
    DurationUnit["MONTH"] = "MONTH";
})(DurationUnit || (exports.DurationUnit = DurationUnit = {}));
var ImportField;
(function (ImportField) {
    ImportField["CLIENT"] = "CLIENT";
    ImportField["COMMISSION"] = "COMMISSION";
    ImportField["DUE_DATE"] = "DUE_DATE";
    ImportField["EFFECTIVE_DATE"] = "EFFECTIVE_DATE";
    ImportField["LINE"] = "LINE";
    ImportField["MEMBERS"] = "MEMBERS";
    ImportField["POLICY_NUMBER"] = "POLICY_NUMBER";
    ImportField["PREMIUM"] = "PREMIUM";
    ImportField["NET_PREMIUM"] = "NET_PREMIUM";
    ImportField["RATE"] = "RATE";
    ImportField["TRANSACTION_CODE"] = "TRANSACTION_CODE";
})(ImportField || (exports.ImportField = ImportField = {}));
var ImportFieldAccessorType;
(function (ImportFieldAccessorType) {
    ImportFieldAccessorType["ADVANCED_FORMULA"] = "ADVANCED_FORMULA";
    ImportFieldAccessorType["BINARY_COLUMN_OPERATION"] = "BINARY_COLUMN_OPERATION";
    ImportFieldAccessorType["COLUMN_COALESCE"] = "COLUMN_COALESCE";
    ImportFieldAccessorType["COLUMN_CONCATENATE"] = "COLUMN_CONCATENATE";
    ImportFieldAccessorType["COLUMN_KEY"] = "COLUMN_KEY";
    ImportFieldAccessorType["MANUAL_DATE_SET"] = "MANUAL_DATE_SET";
    ImportFieldAccessorType["RELATIVE_WORD_RANGE"] = "RELATIVE_WORD_RANGE";
    ImportFieldAccessorType["STATIC_COLUMN_OPERATION"] = "STATIC_COLUMN_OPERATION";
    ImportFieldAccessorType["STATIC_DATE_COLUMN_OPERATION"] = "STATIC_DATE_COLUMN_OPERATION";
})(ImportFieldAccessorType || (exports.ImportFieldAccessorType = ImportFieldAccessorType = {}));
var ImportFieldType;
(function (ImportFieldType) {
    ImportFieldType["DATE"] = "DATE";
    ImportFieldType["NUMBER"] = "NUMBER";
    ImportFieldType["STRING"] = "STRING";
    ImportFieldType["BOOLEAN"] = "BOOLEAN";
})(ImportFieldType || (exports.ImportFieldType = ImportFieldType = {}));
var DepositField;
(function (DepositField) {
    DepositField["AMOUNT"] = "AMOUNT";
    DepositField["DESCRIPTION"] = "DESCRIPTION";
    DepositField["ENTITY_NAME"] = "ENTITY_NAME";
    DepositField["ORIGINATING_COMPANY_ID"] = "ORIGINATING_COMPANY_ID";
    DepositField["ORIGINATING_COMPANY_NAME"] = "ORIGINATING_COMPANY_NAME";
    DepositField["PAYMENT_DATE"] = "PAYMENT_DATE";
    DepositField["PAYMENT_METHOD"] = "PAYMENT_METHOD";
    DepositField["PAYMENT_REFERENCE_KEY"] = "PAYMENT_REFERENCE_KEY";
    DepositField["SHOULD_IGNORE_FOR_PROCESSING"] = "SHOULD_IGNORE_FOR_PROCESSING";
})(DepositField || (exports.DepositField = DepositField = {}));
var TextBasis;
(function (TextBasis) {
    TextBasis["CHAR"] = "CHAR";
    TextBasis["WORD"] = "WORD";
})(TextBasis || (exports.TextBasis = TextBasis = {}));
var RelativePosition;
(function (RelativePosition) {
    RelativePosition["END"] = "END";
    RelativePosition["START"] = "START";
})(RelativePosition || (exports.RelativePosition = RelativePosition = {}));
var StatementRowFilterRuleType;
(function (StatementRowFilterRuleType) {
    StatementRowFilterRuleType["EQUALS"] = "EQUALS";
    StatementRowFilterRuleType["NOT_EQUALS"] = "NOT_EQUALS";
})(StatementRowFilterRuleType || (exports.StatementRowFilterRuleType = StatementRowFilterRuleType = {}));
var ImportFieldOperation;
(function (ImportFieldOperation) {
    ImportFieldOperation["ADD"] = "ADD";
    ImportFieldOperation["DIVIDE"] = "DIVIDE";
    ImportFieldOperation["MULTIPLY"] = "MULTIPLY";
    ImportFieldOperation["SUBTRACT"] = "SUBTRACT";
})(ImportFieldOperation || (exports.ImportFieldOperation = ImportFieldOperation = {}));
var StatementIdentifierField;
(function (StatementIdentifierField) {
    StatementIdentifierField["CARRIER_CODE"] = "CARRIER_CODE";
    StatementIdentifierField["CARRIER_NAME"] = "CARRIER_NAME";
    StatementIdentifierField["DEPARTMENT"] = "DEPARTMENT";
    StatementIdentifierField["DEPOSIT_AMOUNT"] = "DEPOSIT_AMOUNT";
    StatementIdentifierField["DEPOSIT_DATE"] = "DEPOSIT_DATE";
    StatementIdentifierField["DEPOSIT_REFER_NUMBER"] = "DEPOSIT_REFER_NUMBER";
    StatementIdentifierField["DEPOSIT_TYPE"] = "DEPOSIT_TYPE";
    StatementIdentifierField["DIVISION"] = "DIVISION";
    StatementIdentifierField["FILE_NAME"] = "FILE_NAME";
    StatementIdentifierField["IMPORT_DATE"] = "IMPORT_DATE";
    StatementIdentifierField["OFFICE"] = "OFFICE";
    StatementIdentifierField["PERIOD_START"] = "PERIOD_START";
    StatementIdentifierField["PROFIT_CENTER"] = "PROFIT_CENTER";
    StatementIdentifierField["SOURCE_TYPE"] = "SOURCE_TYPE";
    StatementIdentifierField["TOTAL_REVENUE"] = "TOTAL_REVENUE";
    StatementIdentifierField["UPLOADER_INITIALS"] = "UPLOADER_INITIALS";
    StatementIdentifierField["UPLOAD_DATE"] = "UPLOAD_DATE";
    StatementIdentifierField["VAULT_CREDENTIAL_NAME"] = "VAULT_CREDENTIAL_NAME";
})(StatementIdentifierField || (exports.StatementIdentifierField = StatementIdentifierField = {}));
var TagColor;
(function (TagColor) {
    TagColor["AMBER"] = "AMBER";
    TagColor["BLUE"] = "BLUE";
    TagColor["CYAN"] = "CYAN";
    TagColor["EMERALD"] = "EMERALD";
    TagColor["FUCHSIA"] = "FUCHSIA";
    TagColor["GRAY"] = "GRAY";
    TagColor["GREEN"] = "GREEN";
    TagColor["INDIGO"] = "INDIGO";
    TagColor["LIME"] = "LIME";
    TagColor["NEUTRAL"] = "NEUTRAL";
    TagColor["ORANGE"] = "ORANGE";
    TagColor["PINK"] = "PINK";
    TagColor["PURPLE"] = "PURPLE";
    TagColor["RED"] = "RED";
    TagColor["ROSE"] = "ROSE";
    TagColor["SKY"] = "SKY";
    TagColor["SLATE"] = "SLATE";
    TagColor["STONE"] = "STONE";
    TagColor["TEAL"] = "TEAL";
    TagColor["VIOLET"] = "VIOLET";
    TagColor["YELLOW"] = "YELLOW";
    TagColor["ZINC"] = "ZINC";
})(TagColor || (exports.TagColor = TagColor = {}));
