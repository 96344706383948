import { assertDefined } from "../../src/utils";
import { formatCarrierStatementName, pluralize } from "../../src/formatting";
import { SuccessorPayableStatementCreatedNotificationPayloadFragmentFragment } from "../../src/generated/graphql";
import { BaseNotification } from "./BaseNotification";

type SuccessorPayableStatementCreatedNotificationPayload =
  SuccessorPayableStatementCreatedNotificationPayloadFragmentFragment;

export const SuccessorPayableStatementCreatedNotification = ({
  payload,
  createdAt,
}: {
  payload: SuccessorPayableStatementCreatedNotificationPayload;
  createdAt: string;
}) => {
  const successorStatement = payload.successorStatement;
  const predecessorStatement =
    payload.successorStatement?.predecessorPayableCarrierStatement;
  const successorEntries = payload.successorEntries;
  assertDefined(successorStatement);
  assertDefined(predecessorStatement);
  assertDefined(successorEntries);

  const link = `/accounts-payable?statementId=${successorStatement.id}`;

  return (
    <BaseNotification href={link} user={null} createdAt={createdAt}>
      {({ userLabel }) => (
        <>
          <span className="font-medium text-zinc-900">{userLabel}</span>
          {" moved "}
          {successorEntries.length} {" staged "}
          {pluralize(successorEntries.length, "payment", false)}{" "}
          {" to successor "}
          <span className="font-medium text-green-900">
            {formatCarrierStatementName(successorStatement)}
          </span>
        </>
      )}
    </BaseNotification>
  );
};
